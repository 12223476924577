<template>
<i>未開放</i>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({

})
</script>

<style>
</style>